<template>
    <section class='row section-our-partners'>
        <div class="offset-xl-1 col-xl-10">

            <div class="section-heading-our-partners">
                <div class="section-content">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="overflow-box-shadow">
                                <img src="@/assets/img/192.svg"/>
                                <!-- <div class="semi-circle"></div> -->
                            </div>
                        </div>
                        <div class="col-12 col-md-10">
                            <h2 class="title">{{ $t('ourPartners.headerTitle') }}</h2>
                        </div>
                    </div>
                    <p class="paragraph">{{ $t('ourPartners.headerParagraph') }}</p>
                    <a href="#" class="btn-work-with-us">{{ $t('ourPartners.headerButton') }}</a>
                </div>
            </div>

            <!-- Content -->
            <div class="hide-scrollbar">
                <div class="content">
<!--                    <Card3 :title="$t('ourPartners.title1')" image="our-partners-1.jpg" :paragraph="$t('ourPartners.paragraph1')"/>-->
<!--                    <Card3 :title="$t('ourPartners.title2')" image="our-partners-2.jpg" :paragraph="$t('ourPartners.paragraph2')"/>-->
                    <Card3 :title="$t('ourPartners.title3')" image="our-partners-3.jpg" :paragraph="$t('ourPartners.paragraph3')"/>
                    <Card3 :title="$t('ourPartners.title4')" image="our-partners-4.jpg" :paragraph="$t('ourPartners.paragraph4')"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import 'jquery-mousewheel';
import Card3 from '@/components/partials/Card-3.vue';

export default {
    name: "SectionOurPartners",
    components: {
        Card3,
    },
    created: function() {
        $(document).ready(() => {

            var vw = window.innerWidth;
            
            if (vw < 768) { // if viewport is mobile
                
            } else { // if viewport is tablet and larger
                $('.section-our-partners .hide-scrollbar').mousewheel(function(e, delta) { 
                    this.scrollLeft -= (delta * 40); 
                    e.preventDefault(); 
                }); 

                $('.section-our-partners .hide-scrollbar').on('scroll', function() {
                    var elementLeft = $(this).scrollLeft();

                    $('.section-heading-our-partners').css('opacity', 1 - (elementLeft / 400) );
                });
            }
        });
    }, 
};
</script>

<style lang="scss">
@import '../../assets/css/scss/about-us/section-our-partners.scss';
</style>