<template>
  <div class="about-us page">
    <SectionPageHeader />
    <SectionOurCoreValues />
    <SectionOurTeam />
    <SectionOurPartners />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SectionPageHeader from '@/components/about-us/SectionPageHeader.vue';
import SectionOurCoreValues from '@/components/about-us/SectionOurCoreValues.vue';
import SectionOurTeam from '@/components/about-us/SectionOurTeam.vue';
import SectionOurPartners from '@/components/about-us/SectionOurPartners.vue';
import Footer from "@/components/Footer.vue";

export default {
  name: "about-us",
  head: {
    title: { inner: "About Us" }
  },
  components: {
    SectionPageHeader,
    SectionOurCoreValues,
    SectionOurTeam,
    SectionOurPartners,
    Footer
  },
};
</script>

<style lang="scss">
</style>

