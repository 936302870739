<template>
    <div class="card-3">
        <div class="card-content">
          <div class="card-image" v-bind:style="'background-image: url(' + imageURL + ')'"></div>
          <h3 class="title">{{ title }}</h3>
          <p class="paragraph">{{ paragraph }}</p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';

export default {
  name: "Card3",
  props: {
    title: String,
    paragraph: String,
    image: String,
  },
  computed: {
    imageURL: function() {
      return require ('../../assets/img/our-partners/' + this.image);
    }
  }
};
</script>

<style lang="scss">
</style>

