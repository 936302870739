<template>
    <div class="card-4" :number="number">
      <div class="card-progress-bar">
        <div class="gradient-progress"></div>
      </div>
        <div class="card-image">
          <div class="background"></div>
          <img v-if="image" class="image" v-bind:src="imageSrc" /> 
        </div>
        <h3 class="title">{{ title }} <br class="d-en-none" /><span class="d-en-none">TEAM</span></h3>
        <p class="paragraph">{{ paragraph }}</p>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';

export default {
  name: "Card4",
  props: {
    position: Number,
    title: String,
    paragraph: String,
    image: String,
  },
  computed: {
    imageSrc: function() {
      return require ('../../assets/img/icons-about-us-our-team/' + this.image);
    }
  }
};
</script>

<style lang="scss">
</style>

