<template>
    <div class="card-2">
        <h3 class="title">{{ title }}</h3>
        <p class="paragraph">{{ paragraph }}</p>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Card2",
  props: {
    title: String,
    paragraph: String,
  },
};
</script>

<style lang="sass">
</style>

