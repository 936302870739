<template>
    <section class='row section-our-team'>
        <div class="col-12 offset-xl-1 col-xl-10">
            <div class="section-heading-our-team">
                <div class="section-content">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="overflow-box-shadow">
                                <img src="@/assets/img/193.svg"/>
                                <!-- <div class="semi-circle"></div> -->
                            </div>
                        </div>
                        <div class="col-md-10">
                            <h2 class="title">{{ $t('ourTeam.headerTitleFull') }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Content -->
            <div class="hide-scrollbar">
                <div class="content text-justify">
                    <div class="section-content d-lg-none">
                        <div class="overflow-box-shadow">
                            <img src="@/assets/img/193.svg"/>
                            <!-- <div class="semi-circle"></div> -->
                        </div>
                        <h2 class="title">{{ $t('ourTeam.headerTitle1') }}<br>{{ $t('ourTeam.headerTitle2') }}</h2>
                    </div>
                    <Card4 number="1" :title="$t('ourTeam.title1')" image="technical-team.svg" :paragraph="$t('ourTeam.paragraph1')" />
                    <Card4 number="2" :title="$t('ourTeam.title2')" image="risk-control-team.svg" :paragraph="$t('ourTeam.paragraph2')" />
                    <Card4 number="3" :title="$t('ourTeam.title3')" image="product-team.svg" :paragraph="$t('ourTeam.paragraph3')" />
                    <Card4 number="4" :title="$t('ourTeam.title4')" image="customer-service-team.svg" :paragraph="$t('ourTeam.paragraph4')" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import 'jquery-mousewheel';
import Card4 from '@/components/partials/Card-4.vue';

export default {
    name: "SectionOurTeam",
    components: {
        Card4,
    },
    created: function() {
        let quarter = null;

        $(window).resize(() => {
            quarter = ($('.section-our-team .hide-scrollbar').outerWidth() / 4) - 50;
        });

        $(document).ready(() => {
            
            var vw = window.innerWidth;
            
            if (vw < 768) { // if viewport is mobile

                let total = $('.card-4').length;
                
                $('.card-4').each(function(index) {
                    if (index != (total - 1)) {
                        let height = parseInt($(this).css('height').replace('px', '')) + 40;
                        $(this).children('.card-progress-bar').css('height', height + 'px');
                    } 
                });
            } else if (vw < 992) {
                
            } else { // if viewport is tablet and larger
                quarter = ($('.section-our-team .hide-scrollbar').outerWidth() / 4) - 50;

                $('.card-4[number=1] .card-image .background').css('opacity', '1');

                $('.card-4[number=1] .title').css('color', '#3F599A');
                $('.card-4[number=1] .title').css('opacity', '0.7');

                $('.card-4[number=1] .paragraph').css('color', '#3F599A');
                $('.card-4[number=1] .paragraph').css('opacity', '0.8');

                $('.card-4[number=1] .card-progress-bar .gradient-progress').css('opacity', '1');

                $('.section-our-team .hide-scrollbar').mousewheel(function(e, delta) { 
                    this.scrollLeft -= (delta * 40); 
                    e.preventDefault(); 
                }); 

                $('.section-our-team .hide-scrollbar').on('scroll', function() {
                    var elementLeft = $(this).scrollLeft();

                    $('.section-heading-our-team').css('opacity', 1 - (elementLeft / 400) );

                    if (elementLeft > quarter) {
                        $('.card-4[number=2] .card-image .background').css('opacity', '1');

                        $('.card-4[number=2] .title').css('color', '#3F599A');
                        $('.card-4[number=2] .title').css('opacity', '0.7');

                        $('.card-4[number=2] .paragraph').css('color', '#3F599A');
                        $('.card-4[number=2] .paragraph').css('opacity', '0.8');

                        $('.card-4[number=2] .card-progress-bar .gradient-progress').css('opacity', '1');
                    }
                    
                    if (elementLeft > (quarter*1.3) ) {
                        $('.card-4[number=3] .card-image .background').css('opacity', '1');
                        $('.card-4[number=3] .title').css('color', '#3F599A');
                        $('.card-4[number=3] .title').css('opacity', '0.7');

                        $('.card-4[number=3] .paragraph').css('color', '#3F599A');
                        $('.card-4[number=3] .paragraph').css('opacity', '0.8');

                        $('.card-4[number=3] .card-progress-bar .gradient-progress').css('opacity', '1');
                    }

                    if (elementLeft > (quarter*1.6)) {
                        $('.card-4[number=4] .card-image .background').css('opacity', '1');
                        $('.card-4[number=4] .title').css('color', '#3F599A');
                        $('.card-4[number=4] .title').css('opacity', '0.7');

                        $('.card-4[number=4] .paragraph').css('color', '#3F599A');
                        $('.card-4[number=4] .paragraph').css('opacity', '0.8');

                        $('.card-4[number=4] .card-progress-bar .gradient-progress').css('opacity', '1');
                    }

                    if (elementLeft < quarter) {
                        $('.card-4[number=2] .card-image .background').css('opacity', '0');
                        $('.card-4[number=2] .title').css('color', '#D0D2D3');
                        $('.card-4[number=2] .title').css('opacity', '1');

                        $('.card-4[number=2] .paragraph').css('color', '#D0D2D3');
                        $('.card-4[number=2] .paragraph').css('opacity', '1');

                        $('.card-4[number=2] .card-progress-bar .gradient-progress').css('opacity', '0');
                    }

                    if (elementLeft < (quarter*1.3) ) {
                        $('.card-4[number=3] .card-image .background').css('opacity', '0');
                        $('.card-4[number=3] .title').css('color', '#D0D2D3');
                        $('.card-4[number=3] .title').css('opacity', '1');

                        $('.card-4[number=3] .paragraph').css('color', '#D0D2D3');
                        $('.card-4[number=3] .paragraph').css('opacity', '1');

                        $('.card-4[number=3] .card-progress-bar .gradient-progress').css('opacity', '0');
                    }

                    if (elementLeft < (quarter*1.6)) {
                        $('.card-4[number=4] .card-image .background').css('opacity', '0');
                        $('.card-4[number=4] .title').css('color', '#D0D2D3');
                        $('.card-4[number=4] .title').css('opacity', '1');

                        $('.card-4[number=4] .paragraph').css('color', '#D0D2D3');
                        $('.card-4[number=4] .paragraph').css('opacity', '1');

                        $('.card-4[number=4] .card-progress-bar .gradient-progress').css('opacity', '0');
                    }
                });
            }
        });
    },
};
</script>

<style lang="scss">
@import '../../assets/css/scss/about-us/section-our-team.scss';
</style>

