<template>
    <section class='row section-our-core-values'>
        <div class="offset-xl-6 col-12 col-xl-5">
          <div class="content">
            <div class="row row-our-core-values">
              <div class="col-12 col-md-6">
                <Card2 :title="$t('ourCoreValues.title1')" :paragraph="$t('ourCoreValues.paragraph1')" />
              </div>
              <div class="col-12 col-md-6">
                <Card2 :title="$t('ourCoreValues.title2')" :paragraph="$t('ourCoreValues.paragraph2')" />
              </div>
              <div class="col-12 col-md-6">
                <Card2 :title="$t('ourCoreValues.title3')" :paragraph="$t('ourCoreValues.paragraph3')" />
              </div>
              <div class="col-12 col-md-6">
                <Card2 :title="$t('ourCoreValues.title4')" :paragraph="$t('ourCoreValues.paragraph4')" />
              </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import Card2 from '@/components/partials/Card-2.vue';

export default {
    name: "SectionOurCoreValues",
    components: {
      Card2
    },
};
</script>

<style lang="scss">
@import '../../assets/css/scss/about-us/section-our-core-values.scss';
</style>